
.content {
    position: relative;
}

.battery-image-wrap {
    position: absolute;
    top: -100px;
    left: 50%;
    transform: translateX(-50%);

    @media (min-width: 1200px) {
        top: -120px;
    }

    img {
        height: 86px;
        width: auto;
    }
}

.success-ui {

}
.warranty-title {
    text-align: center;
    margin-top: 15px;

    @media (min-width: 1200px) {
        margin-top: 0px;
    }

    .title-text {

    }

    .warranty-end-date {
        font-size: 26px;
        font-weight: bold;

        @media (min-width: 1200px) {
            margin-top: 5px;
        }
    }
}

.talron-replace-success-icon {
    margin-bottom: 8px;
}

.warranty-title-success {
    font-size: 20px;
    font-weight: 900;
    width: 100%;
    text-align: center;
    margin-bottom: 2px;
}
.progress-bar-wrapper {
    margin: 15px auto 15px;

    @media (min-width: 1200px) {
        margin-top: 10px;
    }
}

.title-separator-wrap {
    margin: 40px auto 20px auto;

    @media (min-width: 1200px) {
        margin-top: 10px;
    }
}

.desktop-battery-details {
    margin-top: 30px;
    margin-bottom: 5px;
}

.details-title {
    cursor: pointer;
    padding: 10px 18px;
}

.detail {
    padding: 10px 18px;

    &:nth-child(odd) {
        background-color: var(--global-muted-background);
    }

}

